import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const technicalProductManager = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Technical Product Manager in London. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Technical Product Manager, London,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/76qCJTH9wf8Lwk6BVPhW1K/4714892cbba25119d910f1cd2e759ff2/technical-product-manager.jpeg',
      },
    },
    title: {
      title: 'Technical Product Manager',
    },
  }

  const link = 'https://apply.workable.com/lensesio/j/93F3170AD5/'

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Technical Product Manager</h1>
          <p className="meta">London, Munich, Athens, Madrid </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <p>
              Our product team is working at the intersection of product design
              and delivery, customer success, marketingand engineering to
              deliver usable and valuable solutions to our global customers. As
              part of our product team, you will be contributing to our product
              strategy, collaborating with internal teams and stakeholders,
              analyze metrics, create our roadmap, curate backlogs all the way
              to product releases and evaluate solutions success.
            </p>
            <ul className="pl-4 ">
              <li>
                Delivery: Defining business cases, drive project kick offs,
                refine the backlog and define sprint goals and keep track of
                delivery
                <ul>
                  <li>
                    Define product specs, user stories, mockups and acceptance
                    criteria in collaboration with engineering or independently
                  </li>
                  <li>
                    Collaborate with Engineering, QA and Release teams to plan,
                    drive and execute product releasecycles
                  </li>
                  <li>
                    Align with other teams across the company to ensure our
                    roadmap is unblocked and timelines are in-sync
                  </li>
                </ul>
              </li>
              <li>Make trade-off decisions on platform product features</li>
              <li>
                Customer focused: day-to-day responsibilities will span the
                entire product management lifecycle andinclude deep interaction
                with customers, sales, and engineering:
                <ul>
                  <li>
                    Empathise with our customers, care deeply about their
                    problems to deliver valuable solutions and seamless
                    experiences to operate streaming data and DataOps
                    initiatives
                  </li>
                  <li>
                    Engage with customers, develop insights into what is
                    possible, and uncover unarticulated customer needs to drive
                    customer success
                  </li>
                  <li>
                    Interact and collaborate with Support and field teams, to
                    understand customer issues and drive business outcomes
                  </li>
                  <li>
                    Collaborate with product marketing to support creation of
                    customer driven messaging and content
                  </li>
                  <li>
                    Developing the product roadmap, working collaboratively with
                    internal stakeholders
                  </li>
                </ul>
              </li>
              <li>
                Believe in the value of great enterprise UX across API’s, CLI’s
                and UI’s
                <ul>
                  <li>
                    Measure and report the adoption, performance, and revenue to
                    drive and facilitate product decisions with our stakeholders
                  </li>
                </ul>
              </li>
            </ul>
            <h5 className="pt-3">What you will bring:</h5>

            <p>
              We are looking for team members who are enjoying shipping
              world-class products in a fast-growing industry. Have passion
              about data technologies and making real-time data experiences for
              application developers, data engineers and data scientists to
              drive Customers’ strategic initiatives to a real-time business.
            </p>

            <p>
              Our ideal candidates enjoy working closely with Customers,
              empathize on their problems and want to make them successful with
              our product.
            </p>

            <ul className="pl-4 ">
              <li>Data oriented background</li>
              <li>
                Strong experience as a Product Manager with data and analytics
                products and enterprise applications
              </li>
              <li>
                Good knowledge with Big Data technologies, Apache Kafka,
                Databases, understand streaming concepts& data integration
                scenarios, SQL & Rest APIs, Kubernetes, Cloud providers and
                DevOps practices
              </li>
              <li>
                Ability toBuild compelling and high impact data platform
                capabilities, based on deep understanding of customer needs &
                market trends, translating them into clear priorities and
                product roadmap
              </li>
              <li>
                Ability to understand technical challenges and contribute to
                solutions in collaboration with theengineers and stakeholders
              </li>
              <li>
                Ability to manage internal stakeholders and build relationships,
                and be the ‘go-to’ person for his part ofthe product
              </li>
              <li>
                Strong communication skills, including the ability to translate
                complex technical products and featuresinto simple concepts and
                presentations
              </li>
              <li>
                Fluent and comfortable in working and presenting in English
              </li>
              <li>Enjoyment working with remote teams</li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default technicalProductManager
